<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
         <!-- <v-icon
            @click="pushRouteToView('categories')"
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
          >
            mdi-arrow-left
          </v-icon> -->
          <v-btn
              small
              @click="pushRouteToView()"
              class="mr-4 btn-back"
            >
              <v-icon> mdi-arrow-left </v-icon>
              Voltar
            </v-btn>
          <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon>
          <span v-if="id">Editar </span><span v-else>Adicionar </span> &nbsp;Categoria
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            v-model="setCategoryData"
            ref="categoryFormValidation"
            @submit.prevent="setCategory(category.id)"
          >
            <v-row>
              
              <v-col lg="5" md="5" sm="12" xl="5">
                <v-text-field
                  outlined
                  v-model="category.name"
                  :rules="textRules"
                  label="Nome da Categoria"
                  append-icon="mdi-format-align-justify"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="5" md="5" sm="12" xl="5">
                <number-input
                  label="Valor da Categoria"
                  v-model.number="category.value"
                  placeholder="Valor da Categoria"
                  :rules="textRules"
                  outlined
                  :options="optionsMoney"                  
                  append-icon="mdi-currency-usd"
                  dense
                ></number-input>
              </v-col>
              </v-row>
            <v-row>
              <v-col sm="12" class="text-right">
                <v-btn
                    dark
                    
                    class="mr-4 btn-cancel"
                    @click="pushRouteToView('categories')"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                  </v-btn>

                <v-btn type="submit" color="success">
                  <v-icon left> mdi-content-save </v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import NumberInput from "@/components/NumberInput";
import store from "@/store";
import {
  CATEGORY_GET,
  CATEGORY_CREATE,
  CATEGORY_EDIT,
} from "@/store/actions/categories.type";
import { CATEGORY_RESET_STATE } from "@/store/mutations/categories.type";
import { mapGetters } from "vuex";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

export default {
  name: "categoriesEdit",
  directives: {mask},
  props: {
    id: {
      default: 0,
      type: Number,
      required: false
    }
  },
  components: {
    NumberInput,
  },
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id !== undefined) {
      await store.dispatch(CATEGORY_RESET_STATE);
      await store.dispatch(CATEGORY_GET, to.params.id);
    }
    return next();
  },
  data: () => ({

    optionsMoney: {
      locale: "pt-BR",
      prefix: "R$:",
      length: 7,
      precision: 2,
    },

    setCategoryData: false,

    textRules: [
      (v) => !!v || "Este campo é obrigatório",
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
  }),

  async mounted() {
    // get route params
    // const { id } = this.$route.params;
    // this.form.id = id;
    // store.commit(CATEGORY_RESET_STATE);
    // this.method = await this.checkRoute();
    // this[this.method]();
  },
  methods: {
    pushRouteToView(route) {
      //this.$router.push({ name: route });
      if (this.id) {
                this.$router.push({
                    name: "category",
                    params: {id: this.id}
                });
            } else {
                this.$router.push({name: 'categories'});
            }
    },
    
    async Editar() {
      await Promise.all([store.dispatch(CATEGORY_GET, this.form.id)]);

      this.form.name = this.category.name;
      this.form.value = this.category.value;
      this.form.id = this.category.id;
    },
    setCategory(id) {
            
        if (this.$refs.categoryFormValidation.validate()) {
          let action = id ? CATEGORY_EDIT: CATEGORY_CREATE;
          
          store.commit(CHANGE_PROGRESS, true);
          store
            .dispatch(action)
            .then(response => {
              store.commit(CHANGE_PROGRESS, false);
              this.$refs.categoryFormValidation.resetValidation();
              
              this.snackbar.snackbar = true;
              this.snackbar.text = "Salvo com sucesso";
              this.snackbar.color = "success";
              
              if(response.data.id){
                this.$router.push({
                  name:"category",
                  params:{id: response.data.id}
                })
              }else{
                this.$router.push({
                  name:"category",
                  params:{id: id}
                })
              }
            })
            .catch((response) => {
              store.commit(CHANGE_PROGRESS, false)
              this.snackbar.snackbar = true;
              this.snackbar.text = `Erro ao Salvar Categoria`;
              this.snackbar.color = "error";
              if (typeof response.data.message !== "undefined") {
                this.snackbar.text = response.data.message;
              }
            });
        } else {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Preencha os campos corretamente";
          this.snackbar.color = "error";
        }
               
    },
  },

  computed: {
    ...mapGetters(["category", "snackbar"]),
  },
};
</script>
